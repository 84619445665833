.height400 {
  height: 400px;
}

.liMiDia {
  color: var(--dt_mood_blue);
  list-style-type: none;
  position: relative;
  border-left: 1px solid #656565;
  padding-left: 14px;
  padding-bottom: 10px;
}

.liMiDia:before {
  content: "·";
  font-size: 130px;
  vertical-align: top;
  line-height: 20px;
  position: absolute;
  left: -0.11em;
  top: -4px;
}

.timelineCard {
  background-color: #efeef9ff !important;
}
