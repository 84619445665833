.cardIcon {
  i {
    color: #655dc5;
    font-size: 20px;
  }
}

.checkIcon {
  color: #655dc5;
  font-size: 28px;
}

.selectedCard {
  border: 1px solid #655dc5;
  color: #655dc5;
}

.itemWraper {
  height: 32px;
  width: 32px;
}

.valueCard {
  border: 1px solid #655dc5;
  border-radius: 6px 6px;
  background-color: #ffffff;
  color: #655dc5;
}

.valueCardSelected {
  border: 1px solid #655dc5;
  border-radius: 6px 6px;
  background-color: #e1dffb;
}
