.button {
  height: 40px;
}

.iconDtmood {
  width: 45px;
}

.surveyDescription {
  color: #67757c;
}

.surveyTitle {
  font-size: 21px;
  color: #455a64;
}

.surveyTitleSkeleton {
  height: 40px !important;
}

@media (max-width: 768px) {
  .iconDtmood {
    margin-bottom: 8px;
  }
}

.dialogBody {
  gap: 0;
}

.dialogContent {
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.encuestasBg {
  background-color: var(--mis_encuestas_bg) !important;
}

.titleDiv {
  border-radius: 8px 8px 0px 0px;
  display: flex;
  grid-area: 1 / 1 / 2 / 4;
  justify-content: space-between;
  align-items: center;
}
