.feelingIcon {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}

.selectedFeelingIcon {
  opacity: 1;
  border-radius: 50%;
}
