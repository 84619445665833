.forwardArrow {
  color: var(--dt_mood_blue);
  font-size: 36px;
}

.optionCard:hover * {
  color: var(--dt_mood_blue);
}

.filledIcon {
  display: none !important;
}

.outlinedIcon {
  display: inline !important;
}

.optionCard:hover .filledIcon {
  display: inline !important;
}

.optionCard:hover .outlinedIcon {
  display: none !important;
}

.textRedDtmood {
  color: #dc3545;
}
