.icon {
  color: var(--dt_mood_blue);
  font-size: 36px;
}

.checkIcon {
  color: var(--dt_mood_blue);
  font-size: 28px;
}

.itemWraper {
  height: 32px;
  width: 32px;
}

.valueCard {
  border: 1px var(--dt_mood_blue) solid;
  border-radius: 6px 6px;
  background-color: var(--value_card_bg);
  color: var(--value_card_color);
}

.valueCardSelected {
  border: 1px var(--dt_mood_blue) solid;
  border-radius: 6px 6px;
  background-color: var(--icons);
  color: #ffffff;
}
