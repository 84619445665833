.dtmoodCardImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardFooter {
  margin-top: auto;
}
