.badgeDtmood {
  border-radius: 30px;
  font-size: 12px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.badgeDtmoodPending {
  background-color: #fff9e9ff;
  color: #ffc832;
}

.badgeDtmoodWon {
  background-color: #e8f9f0ff;
  color: #28c66f;
}

.insigniaTabla {
  height: 56px;
  width: 56px;
}

.tablaInsignias {
  tbody tr td {
    color: #455a64;
    padding-bottom: 8px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
  }
  thead tr th {
    color: #67757c;
    padding-bottom: 8px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.titleIcon {
  width: 14px;
}
