.br6 {
  border-radius: 4px;
}

.dialogBody {
  gap: 0;
}

.dialogContent {
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.icono {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 8px;
  width: 34px;
}

.pitazoBg {
  background-color: #ffd3c6 !important;
}

.titleDiv {
  border-radius: 8px 8px 0px 0px;
  display: flex;
  grid-area: 1 / 1 / 2 / 4;
  justify-content: space-between;
  align-items: center;
}
