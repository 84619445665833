.dialogBody {
  gap: 0;
}

.dialogImgDiv {
  width: 100%;
  grid-area: 1 / 1 / 2 / 4;
  gap: 0;
}

.dtmoodCardImage {
  border-radius: 8px 8px 0px 0px;
  width: 100%;
}
