.bgConfetti {
  background-image: url(../../../../assets/images/dt-mood/gifs/confetti.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgStars {
  background-image: url(../../../../assets/images/dt-mood/gifs/stars.gif);
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: 250px;
}

.insigniaModal {
  padding-top: 30px;
  width: 180px;
}

audio::-webkit-media-controls {
  display: none;
}
