.emocionSeleccionada {
  background-color: #efeef9 !important;
  color: #381a46 !important;
}

.br6 {
  border-radius: 4px;
}

.dialogBody {
  gap: 0;
}

.dialogContent {
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.icono {
  padding: 8px;
  width: 34px;
}

.modalHeaderBg {
  background-color: var(--encuestas_bg) !important;
}

.titleDiv {
  border-radius: 8px 8px 0px 0px;
  display: flex;
  grid-area: 1 / 1 / 2 / 4;
  justify-content: space-between;
  align-items: center;
}

.titleIcon {
  width: 18px;
}
