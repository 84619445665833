.fz10 {
  font-size: 10px;
}

.logoLoginDtmood {
  width: 140px;
}

.rowDiv {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 766px) {
  .mw380 {
    max-width: 380px;
  }
}
