.cardDiv {
  width: 100%;
}

.skeletonButton {
  height: 32px;
  width: 96px;
}

.skeletonImg {
  border-radius: 4px 4px 0px 0px;
  height: 140px;
}
