.arrowIcon {
  color: #000000;
  font-size: 22px;
  opacity: 0.3;
}

.openBackground {
  background-color: rgba(116, 96, 238, 0.08);
  border-radius: 4px 4px 0px 0px;
}

.textAnswer {
  color: #67757c;
}

.textAnswer p {
  color: #67757c;
}

.textAnswer ul {
  margin-left: 0.5rem;
  padding-left: 0;
}

.textAnswer ul li {
  margin-bottom: 0.5rem;
}

.textAnswer p:last-child {
  color: #67757c;
}
