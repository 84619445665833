.dtmoodCardImage {
  border-radius: 4px 4px 0px 0px;
  width: 100%;
}

.activasText {
  color: #67757c;
  font-size: 14px;
}

.activasCard {
  background-color: #2cccd3;
  box-shadow: 0px 6px 6px #0e081519;
  border-radius: 8px;
  padding: 16px;
  padding-right: 8px;
  color: #ffffff;
}

.activasCardTitle {
  font-size: 18px;
  font-weight: 600;
}

.activasCardText {
  font-size: 14px;
}

.activasCardIconDiv {
  text-align: right;
  width: 100%;
}

.equalHeightRow {
  display: flex;
  align-items: stretch;
}

.headerModalBg {
  background-color: #e4f699;
}

.dialogBody {
  gap: 0;
}

.dialogContent {
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.icono {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 8px;
  width: 34px;
}

.feedbackBg {
  background-color: var(--mis_encuestas_bg) !important;
}

.titleDiv {
  border-radius: 8px 8px 0px 0px;
  display: flex;
  grid-area: 1 / 1 / 2 / 4;
  justify-content: space-between;
  align-items: center;
}
