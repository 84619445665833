* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 768px) {
  body {
    width: fit-content;
  }
}

:root {
  --spacer: 16px;
  --dt_mood_blue: #655dc5;
  --noticias_eventos_bg: #b4e6eb;
  --evaluaciones_bg: #dcd2ed;
  --headings-color: #455a64;
  --gray-dtmood: #67757c;
  --dark-dtmood: #181b45;
  --icons: #e1dffb;
  --value_card_bg: #ffffff;
  --value_card_color: var(--dt_mood_blue);
  --value_quick_feedback_bg: #e4f699;
  --color-dtmood: #2dccd3;
  --encuestas_bg: #e0dff4;
  --check-light-border: #766ecb;
  --check-light-bg: var(--noticias_eventos_bg);
  --mis_encuestas_bg: #e4f699;
}

.cursor-pointer {
  cursor: pointer;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.text-blue-dtmood {
  color: var(--dt_mood_blue);
}

.text-dark-dtmood {
  color: var(--dark-dtmood);
}

.text-gray-dtmood {
  color: var(--gray-dtmood);
}

.text-color-headings {
  color: var(--headings-color);
}

.text-color-dtmood {
  color: var(--color-dtmood);
}

@media (max-width: 576px) {
  .mt-sm-4 {
    margin-top: var(--spacer) * 1.5;
  }
  .mt-sm-5 {
    margin-top: var(--spacer) * 3;
  }
}

@media (mix-width: 768px) {
  .mt-md-0 {
    margin-top: 0;
  }
}

.grey-scale {
  filter: grayscale(1);
  transition-property: filter;
  transition-duration: 1s;
}

.icon-card-emociones {
  font-size: 32px;
}
