.radioDiv {
  input[type="radio"] {
    box-sizing: border-box;
    appearance: none;
    background: white;
    outline: 1px solid var(--dt_mood_blue);
    border: 0px solid var(--dt_mood_blue);
    border-radius: 2px 2px;
    min-height: 24px;
    min-width: 24px;
  }

  input[type="radio"]:checked {
    background: var(--icons);
  }

  input[type="radio"]:checked::before {
    content: url(../../../../../assets/images/dt-mood/iconos/check-solid-20px.svg);
    position: absolute;
    margin-top: 2px;
  }
}
