.icono {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 8px;
  width: 34px;
}

.cardBg {
  background-color: var(--noticias_eventos_bg);
  border-radius: 4px;
}
