.icon {
  color: var(--dt_mood_blue);
  font-size: 36px;
}

.checkIcon {
  color: var(--dt_mood_blue);
  font-size: 28px;
}

.itemWraper {
  height: 32px;
  width: 32px;
}

.valueCard {
  border: 1px var(--dt_mood_blue) solid;
  border-radius: 6px 6px;
  background-color: var(--value_card_bg);
  color: var(--value_card_color);
}

.valueCardSelected {
  border: 1px var(--dt_mood_blue) solid;
  border-radius: 6px 6px;
  background-color: var(--icons);
  color: #ffffff;
}

.iconoEmocion {
  width: 48px;
}

.emocionSelected {
  border-radius: 50%;
  background-color: rgba(44, 204, 211, 0.3);
  padding: 1px;
}

.headerModalBg {
  background-color: #e0dff4;
}

.cardIcon {
  i {
    color: #655dc5;
    font-size: 20px;
  }
}

.checkIcon {
  color: #655dc5;
  font-size: 28px;
}

.selectedCard {
  border: 1px solid #655dc5;
  color: #655dc5;
}

.itemWraper {
  height: 32px;
  width: 32px;
}

.valueCard {
  border: 1px solid #655dc5;
  border-radius: 6px 6px;
  background-color: #ffffff;
  color: #655dc5;
}

.titleNumber {
  border: 1px solid #707070;
  border-radius: 50%;
  color: #0c1523;
  font-size: 14px;
  font-weight: 400;
  height: 24px;
  width: 24px;
  text-align: center;
  margin-right: 8px;
}

.hidden {
  width: 0px;
  opacity: 0;
}
